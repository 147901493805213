/*
 * Name: main.js
 * Description: The main entry point of the app.
 * Created by: Jana Schumann on 2018-03-09
 * Last edited by: Milena Malysheva on 2022-12-23
 */

import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue'
import VueRouter from 'vue-router'
import VueCookies from 'vue-cookies'

import router from './plugins/router'
import vuetify from './plugins/vuetify'
import store from './plugins/store'
import axiosInstance from './api'

import App from './App.vue'

import '@/assets/css/vuetify/main.scss'
import '@/assets/css/global/main.scss'

import CustomExpansionPanelItem from './components/custom-elements/CustomExpansionPanelItem'
import CustomExpansionPanel from './components/custom-elements/CustomExpansionPanel'

// custom components
Vue.component('custom-expansion-panel', CustomExpansionPanel)
Vue.component('custom-expansion-panel-item', CustomExpansionPanelItem)

// https://stackoverflow.com/a/55544290
Vue.use(VueRouter)
Vue.use(VueCookies)

Vue.$cookies.config(
  null,
  null,
  window.location.hostname,
  window.location.hostname !== 'localhost',
  'Strict'
)

// we could directly initialize the token and the roles in the auth module data initialization, but we implemented
// a new array format for the roles and in case a user had the roles stored in the old format, we need to handle
// the parse error correctly
// https://stackoverflow.com/questions/45290003/router-beforeeach-guard-executed-before-state-loaded-in-vue-created
try {
  store.dispatch('initAuthState')
} catch {
  store.dispatch('authLogout')
}

// an idea for refactoring: https://confluence.neotiv.com/display/WEBPORT/axios+integration
Vue.prototype.$axios = axiosInstance

Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
