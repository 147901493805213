<!--
 Name: CustomExpansionPanel.vue
 Description: Component to handle the custom expansion panel.
 Created by: Jana Schumann on 2018-07-29
 Last edited by: Milena Malysheva on 2022-11-11
-->

<template>
  <v-container class="px-0" fluid>
    <v-card>
      <draggable
        class="pa-0 mt-4"
        tag="ul"
        v-model="localModel"
        :move="onLocalOrderMove"
        @change="onLocalOrderChange"
        @start="onLocalStartDrag"
        @end="onLocalEndDrag"
        v-bind="localDragOptions()"
      >
        <transition-group type="transition" :name="'flip-list'">
          <slot></slot>
        </transition-group>
      </draggable>
    </v-card>
    <v-row v-show="noDataMatching" justify="center" my-4>
      There are no data matching entered search query
    </v-row>
  </v-container>
</template>

<script>
import draggable from 'vuedraggable'

export default {
  provide () {
    return { customExpansionPanel: this }
  },
  props: [
    'value',
    'onOrderChange',
    'onOrderMove',
    'onStartDrag',
    'onEndDrag',
    'dragOptions',
    'search',
    'searchOptions'
  ],
  components: {
    draggable
  },
  data: () => ({
    items: [],
    open: [],
    localModel: [],
    draggableMode: true,
    noDataMatching: false
  }),
  methods: {
    // to access custom expansion panel items methods
    register (uid, toggle, focusOnItem, show, hide, open) {
      this.items.push({ uid, toggle, focusOnItem, show, hide, open })
    },
    unregister (uid) {
      const index = this.items.findIndex(i => i.uid === uid)
      this.items.splice(index, 1)
    },
    // overriding draggable event handlers
    onLocalOrderChange (evt) {
      for (let i = 0; i < this.items.length; i++) {
        this.items[i].open = false
        this.items[i].toggle(false)
      }
      if (this.onOrderChange) {
        this.onOrderChange(evt, this.localModel)
      }
    },
    onLocalOrderMove (evt, originalEvent) {
      if (this.onOrderMove) {
        return this.onOrderMove(evt, originalEvent)
      }
      // SonarQube requirement
      return null
    },
    onLocalStartDrag (evt) {
      if (this.onStartDrag) {
        this.onStartDrag(evt)
      }
    },
    onLocalEndDrag (evt) {
      if (this.onEndDrag) {
        this.onEndDrag(evt)
      }
    },
    localDragOptions () {
      this.draggableMode = !this.dragOptions.disabled
      return { ...this.dragOptions, handle: '.drag-indicator' }
    },
    // controller functions
    panelClick (uid) {
      const items = this.items.slice()
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].uid === uid) {
          items[i].open = !this.items[i].open
          break
        }
      }
      this.updatePanels(items)
    },
    updatePanels (items) {
      this.items = items
      for (let i = 0; i < this.items.length; i++) {
        const itemOpen = items && items[i].open
        this.items[i].toggle(itemOpen)
      }
    },
    onItemAdded () {
      this.$nextTick(() => {
        this.items[this.items.length - 1].toggle(true)
        this.items[this.items.length - 1].focusOnItem()
      })
    }
  },
  watch: {
    search (newVal) {
      this.noDataMatching = true // if there are no items that match search query 'there are no data available' text should be shown
      // if search is enabled
      for (let i = 0; i < this.localModel.length; i++) {
        let show = false
        for (const field of this.searchOptions.searchFields) {
          // if there is no search text entered => show all the items
          // if an item contains search query as substring => show the item
          if (!newVal || this.localModel[i][field].toString().toLowerCase().includes(newVal.toLowerCase())) {
            show = true
            this.noDataMatching = false
            break
          }
        }
        if (show) {
          this.items[i].show()
        } else {
          this.items[i].hide()
        }
      }
    },
    value (newVal) {
      this.localModel = [...newVal]
    }
  },
  created () {
    this.localModel = [...this.value]
  }
}
</script>
