/**
 * Name: index.js
 * Description: Route handler.
 * Created by: Jana Schumann on 2018-03-09
 * Last edited by: Lokeswari Madhusudhana on 2024-02-14
 */

import VueRouter from 'vue-router'
import store from '@/plugins/store'

import _ from 'lodash'

import { ROLES } from '@/constants/users'
import { PARADIGMS, PARADIGM_NAMES } from '@/constants/paradigms'
import { PROJECTS, PROJECT_NAMES } from '@/constants/projects'

export const defaultRoutes = {
  [ROLES.ADMIN]: 'Admin Dashboard',
  [ROLES.SCIENTIST]: 'Scientist Dashboard',
  [ROLES.BUSINESS_MANAGER]: 'Projects',
  [ROLES.UNAUTHENTICATED]: 'Home'
}

// Lazy loading + chunks config
// https://jira.neotiv.com/browse/SD-1718
// unrestricted access routes + unauthenticated user routes => "unrestricted-routes" chunk
const Data = () => import(/* webpackChunkName: "unrestricted-routes" */ '@/components/home/Data')
const Legal = () => import(/* webpackChunkName: "unrestricted-routes" */ '@/components/home/Legal')
const Home = () => import(/* webpackChunkName: "unrestricted-routes" */ '@/components/home/Home')
const Login = () => import(/* webpackChunkName: "unrestricted-routes" */ '@/components/home/Login')

// scientist routes => "scientist-routes" chunk
const ScientistDashboard = () => import(/* webpackChunkName: "scientist-routes" */ '@/components/scientist/Dashboard')
const ScientistHelp = () => import(/* webpackChunkName: "scientist-routes" */ '@/components/scientist/Help')
const ScientistSettings = () => import(/* webpackChunkName: "scientist-routes" */ '@/components/scientist/Settings')
const ScientistQuestionLibrary = () => import(/* webpackChunkName: "scientist-routes" */ '@/components/scientist/question-library/QuestionLibrary')
const DataDownload = () => import(/* webpackChunkName: "scientist-routes" */ '@/components/scientist/DataDownload')

const ScientistStudies = () => import(/* webpackChunkName: "scientist-routes" */ '@/components/scientist/studies/Studies')
const ScientistNewStudy = () => import(/* webpackChunkName: "scientist-routes" */ '@/components/scientist/studies/NewStudy')
const ScientistStudyDetails = () => import(/* webpackChunkName: "scientist-routes" */ '@/components/scientist/studies/StudyDetails')
const ScientistAssignmentDetails = () => import(/* webpackChunkName: "scientist-routes" */ '@/components/scientist/studies/assignments/AssignmentDetails')

// admin routes => "admin-routes" chunk
const AdminDashboard = () => import(/* webpackChunkName: "admin-routes" */ '@/components/admin/Dashboard')
const QuestionLibrary = () => import(/* webpackChunkName: "admin-routes" */ '@/components/admin/question-library/QuestionLibrary')
const TextEditor = () => import(/* webpackChunkName: "admin-routes" */ '@/components/admin/text-editor/TextEditor')
const ActivationCodes = () => import(/* webpackChunkName: "admin-routes" */ '@/components/admin/activation-codes/ActivationCodes')
const InsideOutside = () => import(/* webpackChunkName: "admin-routes" */ '@/components/admin/stimuli/InsideOutside')
const PatternCompletion = () => import(/* webpackChunkName: "admin-routes" */ '@/components/admin/stimuli/PatternCompletion')
const PatternSeparation = () => import(/* webpackChunkName: "admin-routes" */ '@/components/admin/stimuli/PatternSeparation')
const VisionTest = () => import(/* webpackChunkName: "admin-routes" */ '@/components/admin/stimuli/VisionTest')
const ParadigmTemplate = () => import(/* webpackChunkName: "admin-routes" */ '@/components/admin/templates/ParadigmTemplate')
const ProjectsOverview = () => import(/* webpackChunkName: "admin-routes" */ '@/components/admin/projects/ProjectsOverview')
const SocialHealthInsurance = () => import(/* webpackChunkName: "admin-routes" */ '@/components/admin/projects/SocialHealthInsurance')
const Physicians = () => import(/* webpackChunkName: "admin-routes" */ '@/components/admin/projects/Physicians')
const ProjectsRelation = () => import(/* webpackChunkName: "admin-routes" */ '@/components/admin/projects/ProjectsRelation.vue')

const router = new VueRouter({
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    // unrestricted access routes
    {
      path: '/data',
      name: 'Data Protection',
      component: Data
    },
    {
      path: '/legal',
      name: 'Legal Disclosure',
      component: Legal
    },
    // unauthenticated user routes
    {
      path: '',
      name: 'Home',
      component: Home,
      meta: {
        requiresAuth: [ROLES.UNAUTHENTICATED]
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        requiresAuth: [ROLES.UNAUTHENTICATED]
      }
    },
    // scientist routes
    {
      path: '/scientist',
      name: 'Scientist Dashboard',
      component: ScientistDashboard,
      meta: {
        requiresAuth: [ROLES.SCIENTIST]
      }
    },
    {
      path: '/help',
      name: 'Scientist Help',
      component: ScientistHelp,
      meta: {
        requiresAuth: [ROLES.SCIENTIST]
      }
    },
    {
      path: '/data-download',
      name: 'Data Download',
      component: DataDownload,
      meta: {
        requiresAuth: [ROLES.SCIENTIST]
      }
    },
    {
      path: '/scientist-question-library',
      name: 'Scientist Question Library',
      component: ScientistQuestionLibrary,
      meta: {
        requiresAuth: [ROLES.SCIENTIST]
      }
    },
    {
      path: '/scientist-studies',
      name: 'Studies',
      component: ScientistStudies,
      meta: {
        requiresAuth: [ROLES.SCIENTIST]
      }
    },
    {
      path: '/scientist-new-study',
      name: 'New Study',
      component: ScientistNewStudy,
      meta: {
        requiresAuth: [ROLES.SCIENTIST]
      }
    },
    {
      path: '/scientist-settings',
      name: 'Settings',
      component: ScientistSettings,
      meta: {
        requiresAuth: [ROLES.SCIENTIST]
      }
    },
    {
      path: '/study/:studyId(\\d+)',
      name: 'Study',
      component: ScientistStudyDetails,
      meta: {
        requiresAuth: [ROLES.SCIENTIST]
      },
      props: route => ({
        studyId: parseInt(route.params.studyId, 10)
      })
    },
    {
      path: '/study/:studyId(\\d+)/assignment/:assignmentId(\\d+)',
      component: ScientistAssignmentDetails,
      name: 'Assignment',
      meta: {
        requiresAuth: [ROLES.SCIENTIST]
      },
      props: route => ({
        studyId: parseInt(route.params.studyId, 10),
        assignmentId: parseInt(route.params.assignmentId, 10)
      })
    },
    // admin routes
    {
      path: '/admin',
      name: 'Admin Dashboard',
      component: AdminDashboard,
      meta: {
        requiresAuth: [ROLES.ADMIN]
      }
    },
    {
      path: '/question-library',
      name: 'Question Library',
      component: QuestionLibrary,
      meta: {
        requiresAuth: [ROLES.ADMIN]
      }
    },
    {
      path: '/text-editor',
      name: 'Text Editor',
      component: TextEditor,
      meta: {
        requiresAuth: [ROLES.ADMIN]
      }
    },
    {
      path: '/activation-codes',
      name: 'Activation codes',
      component: ActivationCodes,
      meta: {
        requiresAuth: [ROLES.ADMIN]
      }
    },
    {
      path: '/stimuli',
      name: 'Stimuli Library',
      component: {
        // Inline declaration of a component that renders our <router-view>
        render: (c) => c('router-view')
      },
      meta: {
        requiresAuth: [ROLES.ADMIN]
      },
      children: [
        {
          path: PARADIGMS.IO,
          name: PARADIGM_NAMES.IO,
          component: InsideOutside
        },
        {
          path: PARADIGMS.PC,
          name: PARADIGM_NAMES.PC,
          component: PatternCompletion
        },
        {
          path: PARADIGMS.PS,
          name: PARADIGM_NAMES.PS,
          component: PatternSeparation
        },
        {
          path: PARADIGMS.VT,
          name: PARADIGM_NAMES.VT,
          component: VisionTest
        }
      ]
    },
    {
      path: '/templates/:template',
      name: 'Stimuli Set Templates',
      component: ParadigmTemplate,
      props: true,
      meta: {
        requiresAuth: [ROLES.ADMIN]
      }
    },
    {
      path: '/projects',
      redirect: '/projects/overview',
      name: 'Projects',
      component: {
        render: (c) => c('router-view')
      },
      meta: {
        requiresAuth: [ROLES.ADMIN, ROLES.BUSINESS_MANAGER]
      },
      children: [
        {
          path: PROJECTS.PO,
          name: PROJECT_NAMES.PO,
          component: ProjectsOverview
        },
        {
          path: PROJECTS.SHI,
          name: PROJECT_NAMES.SHI,
          component: SocialHealthInsurance
        },
        {
          path: PROJECTS.PR,
          name: PROJECT_NAMES.PR,
          component: ProjectsRelation
        },
        {
          path: PROJECTS.PY,
          name: PROJECT_NAMES.PY,
          component: Physicians
        }
      ]
    },
    // handles wildcards
    {
      path: '*',
      redirect: '/'
    }
  ]
})

router.beforeEach((to, from, next) => {
  const checks = _.merge({}, ...to.matched)
  const baseUserRole = store.getters.baseRole
  if (!checks.meta.requiresAuth || checks.meta.requiresAuth.some(role => role === baseUserRole)) {
    next()
  } else {
    next({ name: defaultRoutes[baseUserRole] })
  }
})

export default router
