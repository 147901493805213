<!--
 Name: SnackbarQueue.vue
 Description: Component to display snackbars globally.
 Created by: Milena Malysheva on 2019-08-02
 Last edited by: Milena Malysheva on 2020-01-08
-->

<template>
  <v-snackbar
    :timeout="snackbar.timeout"
    :top="true"
    :color="snackbar.color"
    v-model="isOpened">
    {{ snackbar.text }}
    <v-btn text color="white" @click.native="isOpened = false">Close</v-btn>
  </v-snackbar>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  data: () => ({
    isOpened: false
  }),
  methods: {
    ...mapActions('snackbarQueue', ['closeSnackbar'])
  },
  watch: {
    snackbarQueue (val) {
      this.$nextTick(() => {
        this.isOpened = !!val.length
      })
    },
    isOpened (val) {
      if (!val) {
        this.closeSnackbar()
      }
    }
  },
  computed: {
    ...mapState('snackbarQueue', ['snackbarQueue']),
    snackbar () {
      if (this.snackbarQueue.length) {
        return {
          text: this.snackbarQueue[0].text,
          color: this.snackbarQueue[0].color,
          timeout: this.snackbarQueue[0].timeout || 3000
        }
      }
      return {}
    }
  }
}
</script>
