<!--
 Name: CustomExpansionPanelItem.vue
 Description: Component to handle the custom expansion panel item.
 Created by: Jana Schumann on 2018-07-29
 Last edited by: Milena Malysheva on 2022-09-09
-->

<template>
  <div v-show="!hidden">
    <v-list-item class="header">
      <v-list-item-icon v-if="customExpansionPanel.draggableMode">
        <v-icon class="drag-indicator" color="grey darken-2">drag_indicator</v-icon>
      </v-list-item-icon>
      <v-list-item-content class="py-0">
        <v-list-item-title ref='itemHeader'>
          <slot name="header"></slot>
        </v-list-item-title>
      </v-list-item-content>
      <v-btn icon ripple @click="customExpansionPanel.panelClick(_uid)">
        <v-icon v-if="!open" color="grey darken-2">expand_more</v-icon>
        <v-icon v-if="open" color="grey darken-2">expand_less</v-icon>
      </v-btn>
    </v-list-item>
    <transition name="slide-fade">
      <div class="px-6">
        <slot v-if="open" name="content"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  inject: ['customExpansionPanel'],
  data: () => ({
    open: false,
    hidden: false
  }),
  methods: {
    toggle (open) {
      this.open = open
    },
    focusOnItem () {
      this.$refs.itemHeader.scrollIntoView()
    },
    hide () {
      this.hidden = true
    },
    show () {
      this.hidden = false
    }
  },
  mounted () {
    this.customExpansionPanel.register(this._uid, this.toggle, this.focusOnItem, this.show, this.hide, this.open)
  },

  beforeDestroy () {
    this.customExpansionPanel.unregister(this._uid)
  }
}
</script>

<style>

  .drag-indicator {
    cursor: move;
  }

  ul {
    padding-left: 0;
  }

  .header {
    border-bottom: lightgray 1px solid;
  }

  .slide-fade-enter-active {
    transition: all .5s ease;
  }

  .slide-fade-leave-active {
    transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateY(10px);
    opacity: 0;
  }
</style>
