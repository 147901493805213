/**
 * Name: index.js
 * Description: The main file for the application store.
 * Created by: Jana Schumann on 2018-03-09
 * Last edited by: Milena Malysheva on 2019-08-02
 */

import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth'
import sidebar from './modules/sidebar'
import snackbarQueue from './modules/snackbarQueue'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    auth,
    sidebar,
    snackbarQueue
  },
  strict: debug
})
