/*
 * Name: users.js
 * Description: Contains users-related constants.
 * Created by: Milena Malysheva on 2022-10-10
 * Last edited by: Lokeswari Madhusudhana on 2024-02-14
 */

// user roles - api mapping
// https://neotiv.atlassian.net/jira/software/c/projects/SD/boards/27?modal=detail&selectedIssue=SD-2395
const ROLES = {
  ADMIN: 'NeotivAdmin',
  SCIENTIST: 'Scientist',
  BUSINESS_MANAGER: 'BusinessManager',
  UNAUTHENTICATED: ''
}

// status of a scientist
const SCIENTIST_STATUS = {
  REGISTERED: 2, // number are used for sorting
  PENDING: 1,
  LINK_EXPIRED: 0
}

// scientist invitation link is valid for 2 days
const INVITATION_LINK_EXPIRATION_TIME = 2

export {
  ROLES,
  SCIENTIST_STATUS,
  INVITATION_LINK_EXPIRATION_TIME
}
