/*
 * Name: projects.js
 * Description: Contains projects-related constants.
 * Created by: Lokeswari Madhusudhana on 2023-09-25
 * Last edited by: Lokeswari Madhusudhana on 2024-02-14
 */

const PROJECTS = {
  PO: 'overview',
  SHI: 'social-health-insurance',
  PR: 'projects-relation',
  PY: 'physicians'
}

const PROJECT_NAMES = {
  [PROJECTS.PO]: 'Overview',
  [PROJECTS.SHI]: 'Social Health Insurance',
  [PROJECTS.PR]: 'Projects Relation',
  [PROJECTS.PY]: 'Physicians'
}

export {
  PROJECTS,
  PROJECT_NAMES
}
