/*
 * Name: encryptor.js
 * Description: Contains methods for data encryption.
 * Created by: Milena Malysheva on 2019-10-01
 * Last edited by: Milena Malysheva on 2020-12-21
 */

import CryptoJS from 'crypto-js'

import { COOKIES_ENCRYPTION_KEY } from '@/constants/general'

export function encrypt (str) {
  return CryptoJS.AES.encrypt(str, COOKIES_ENCRYPTION_KEY)
}

export function decrypt (str) {
  return str ? CryptoJS.AES.decrypt(str, COOKIES_ENCRYPTION_KEY).toString(CryptoJS.enc.Utf8) : null
}
