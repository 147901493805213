/*
 * Name: steps.js
 * Description: Contains steps-related constants for an assignment.
 * Created by: Milena Malysheva on 2022-10-10
 * Last edited by: Milena Malysheva on 2022-10-10
 */

import { PARADIGMS, PARADIGM_NAMES, PARADIGM_PHASES } from '@/constants/paradigms'

const ASSIGNMENT_STEPS_API_TYPES = {
  FADE_LEARNING: 'FadeLearning',
  FADE_RETRIEVAL: 'FadeRetrieval',
  PATTERN_COMPLETION: 'PatternCompletion',
  PATTERN_SEPARATION: 'PatternSeparation',
  VISION_TEST: 'VisionTest',
  QUESTIONNAIRE_INTRODUCTION: 'QuestionnaireIntroduction',
  INFORMATION: 'Information',
  QUESTIONNAIRE: 'Questionnaire'
}
// should be moved to the localizations files if we introduce other languages to the WP
const ASSIGNMENT_STEPS_CLIENT_TYPES = {
  INSIDE_OUTSIDE: PARADIGM_NAMES[PARADIGMS.IO],
  PATTERN_COMPLETION: PARADIGM_NAMES[PARADIGMS.PC],
  PATTERN_SEPARATION: PARADIGM_NAMES[PARADIGMS.PS],
  VISION_TEST: PARADIGM_NAMES[PARADIGMS.VT],
  QUESTION_INTRODUCTION: 'Question Introduction',
  INFORMATION: 'Information',
  QUESTION: 'Question'
}

const ASSIGNMENT_STEPS_MAPPING = {
  [ASSIGNMENT_STEPS_API_TYPES.FADE_LEARNING]: {
    clientType: ASSIGNMENT_STEPS_CLIENT_TYPES.INSIDE_OUTSIDE,
    url: `${PARADIGMS.IO}/${PARADIGM_PHASES.LEARNING.toLowerCase()}`,
    phase: PARADIGM_PHASES.LEARNING
  },
  [ASSIGNMENT_STEPS_API_TYPES.FADE_RETRIEVAL]: {
    clientType: ASSIGNMENT_STEPS_CLIENT_TYPES.INSIDE_OUTSIDE,
    url: `${PARADIGMS.IO}/${PARADIGM_PHASES.RETRIEVAL.toLowerCase()}`,
    phase: PARADIGM_PHASES.RETRIEVAL
  },
  [ASSIGNMENT_STEPS_API_TYPES.PATTERN_COMPLETION]: {
    clientType: ASSIGNMENT_STEPS_CLIENT_TYPES.PATTERN_COMPLETION,
    url: PARADIGMS.PC
  },
  [ASSIGNMENT_STEPS_API_TYPES.PATTERN_SEPARATION]: {
    clientType: ASSIGNMENT_STEPS_CLIENT_TYPES.PATTERN_SEPARATION,
    url: PARADIGMS.PS
  },
  [ASSIGNMENT_STEPS_API_TYPES.VISION_TEST]: {
    clientType: ASSIGNMENT_STEPS_CLIENT_TYPES.VISION_TEST,
    url: PARADIGMS.VT
  },
  [ASSIGNMENT_STEPS_API_TYPES.QUESTIONNAIRE_INTRODUCTION]: {
    clientType: ASSIGNMENT_STEPS_CLIENT_TYPES.QUESTION_INTRODUCTION
  },
  [ASSIGNMENT_STEPS_API_TYPES.INFORMATION]: {
    clientType: ASSIGNMENT_STEPS_CLIENT_TYPES.INFORMATION
  },
  [ASSIGNMENT_STEPS_API_TYPES.QUESTIONNAIRE]: {
    clientType: ASSIGNMENT_STEPS_CLIENT_TYPES.QUESTION
  }
}
export {
  ASSIGNMENT_STEPS_API_TYPES,
  ASSIGNMENT_STEPS_CLIENT_TYPES,
  ASSIGNMENT_STEPS_MAPPING
}
