/*
 * Name: general.js
 * Description: Contains general constants for the application.
 * Created by: Milena Malysheva on 2022-10-10
 * Last edited by: Milena Malysheva on 2022-12-21
 */

// environments,
const API_ENVIRONMENTS = {
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  PRODUCTION: 'production'
}
const BASE_URL_MAP = {
  [API_ENVIRONMENTS.DEVELOPMENT]: 'https://dev-api.neotiv-care.com',
  [API_ENVIRONMENTS.STAGING]: 'https://staging-api.neotiv-care.com',
  [API_ENVIRONMENTS.PRODUCTION]: 'https://api.neotiv-care.com'
}

// verification code for 2FA is valid for 5 minutes
const VERIFICATION_CODE_LIFETIME = 300

// the key used for AES encryption of the 'role' cookie
// see ticket SD-669
// of course it's not secure to store it in js, but it is as good as it gets without SSR
const COOKIES_ENCRYPTION_KEY = 'TWpWZn2YDJ8_xzdz(N<9'

export {
  API_ENVIRONMENTS,
  BASE_URL_MAP,
  VERIFICATION_CODE_LIFETIME,
  COOKIES_ENCRYPTION_KEY
}
