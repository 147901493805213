/**
 * Name: auth.js
 * Description: Auth store module.
 * Created by: Jana Schumann on 2018-03-19
 * Last edited by: Lokeswari Madhusudhana on 2023-04-13
 */

/* eslint-disable promise/param-names */
import cookies from 'vue-cookies'

import { encrypt, decrypt } from '@/helpers/encryptor'
import { ROLES } from '@/constants/users'

// for now we can't have multiple roles in care WP, but it's probably better to have them stored in an array =>
// - users might have multiple roles in the future
// - backend sends roles as an array
// - in trials WP users can have multiple roles and it is better to keep care WP similar to the trials
const state = {
  token: '',
  roles: []
}

const getters = {
  isAuthenticated: state => !!(state.token && state.roles.length),
  // here is the only place that differs from the trials
  baseRole: (state, getters) => {
    if (getters.isAuthenticated && Object.values(ROLES).includes(state.roles[0])) {
      return state.roles[0]
    }
    return ROLES.UNAUTHENTICATED
  }
}

const actions = {
  authSuccess ({ commit, dispatch }, { token, roles }) {
    cookies.set('user-token', token, '8h')
    cookies.set('user-roles', encrypt(JSON.stringify(roles)).toString(), '8h')

    commit('authSuccess', { token, roles })
  },
  authLogout ({ commit }) {
    cookies.remove('user-token', null, window.location.hostname)
    cookies.remove('user-roles', null, window.location.hostname)

    commit('authLogout')
  },
  initAuthState ({ commit, dispatch }) {
    const token = cookies.get('user-token') || ''
    const roles = JSON.parse(decrypt(cookies.get('user-roles'))) || []
    if (Object.values(ROLES).includes(roles[0])) {
      commit('authSuccess', { token, roles })
    } else {
      dispatch('authLogout')
    }
  }
}

const mutations = {
  authSuccess (state, { token, roles }) {
    state.token = token
    state.roles = roles
  },
  authLogout (state) {
    state.token = ''
    state.roles = []
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
