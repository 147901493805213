/*
 * Name: paradigms.js
 * Description: Contains paradigms-related constants.
 * Created by: Milena Malysheva on 2022-10-10
 * Last edited by: Milena Malysheva on 2022-10-10
 */

// api mapping
const PARADIGMS = {
  PC: 'pattern-completion',
  PS: 'pattern-separation',
  VT: 'vision-test',
  IO: 'fade'
}
// should be moved to the localizations files if we introduce other languages to the WP
const PARADIGM_NAMES = {
  [PARADIGMS.PC]: 'Pattern Completion',
  [PARADIGMS.PS]: 'Pattern Separation',
  [PARADIGMS.VT]: 'Vision Test',
  [PARADIGMS.IO]: 'Inside Outside'
}

// TEMPLATES
// api mapping for IO, PC
const PARADIGM_PHASES = {
  LEARNING: 'Learning',
  RETRIEVAL: 'Retrieval'
}
// api mapping for PS
const PARADIGM_LEVELS = {
  LEVEL1: 'Level1',
  LEVEL2: 'Level2'
}
// should be moved to the localizations files if we introduce other languages to the WP
const PARADIGM_LEVELS_NAMES = {
  [PARADIGM_LEVELS.LEVEL1]: 'Level 1',
  [PARADIGM_LEVELS.LEVEL2]: 'Level 2'
}

// STIMULI
const INSIDE_OUTSIDE_SCENE_TYPES = {
  INSIDE: 'Inside',
  OUTSIDE: 'Outside'
}
const STIMULUS_TYPES = {
  OBJECT: 'Object',
  ROOM: 'Room'
}
const DISPLAY_MODES = {
  EITHER_SAME_OR_DIFFERENT: 'EitherSameOrDifferent',
  ALWAYS_DIFFERENT: 'AlwaysDifferent',
  ALWAYS_SAME: 'AlwaysSame'
}
// should be moved to the localizations files if we introduce other languages to the WP
const DISPLAY_MODES_NAMES = {
  [DISPLAY_MODES.EITHER_SAME_OR_DIFFERENT]: 'Same or different',
  [DISPLAY_MODES.ALWAYS_DIFFERENT]: 'Always different',
  [DISPLAY_MODES.ALWAYS_SAME]: 'Always same'
}

// stimuli images
// stimuli images (PS, VT)
const IMAGE_VERSIONS = {
  IMAGE_A: 'ImageA',
  IMAGE_B: 'ImageB'
}
// stimuli images (PC)
const EMPTY_FULL_SCENE_TYPES = {
  EMPTY: 'Empty',
  FULL: 'Full'
}
const OBJECT_TYPES = {
  OBJECT_A: 'ObjectA',
  OBJECT_B: 'ObjectB',
  OBJECT_C_LEARNING: 'ObjectCLearning',
  OBJECT_C_RETRIEVAL: 'ObjectCRetrieval'
}
const IMAGE_TYPES = {
  ...EMPTY_FULL_SCENE_TYPES,
  ...OBJECT_TYPES
}

export {
  PARADIGMS,
  PARADIGM_NAMES,
  PARADIGM_PHASES,
  PARADIGM_LEVELS,
  PARADIGM_LEVELS_NAMES,
  INSIDE_OUTSIDE_SCENE_TYPES,
  STIMULUS_TYPES,
  DISPLAY_MODES,
  DISPLAY_MODES_NAMES,
  IMAGE_VERSIONS,
  IMAGE_TYPES
}
