/*
 * Name: settings.js
 * Description: Contains settings constants for a study.
 * Created by: Milena Malysheva on 2022-10-10
 * Last edited by: Milena Malysheva on 2022-10-10
 */

import { ASSIGNMENT_STEPS_API_TYPES } from '@/constants/studies/assignments/steps'

// api mapping, for now this is the only available option
const SCHEDULING_STRATEGY = 'WhenPreviousEnds'

// api mapping
const CATEGORIES = {
  QUESTIONNAIRE: 'Questionnaire',
  INSIDE_OUTSIDE_LEARNING: 'InsideOutsideLearning',
  INSIDE_OUTSIDE_RETRIEVAL: 'InsideOutsideRetrieval',
  PATTERN_COMPLETION_LEARNING: 'PatternCompletionLearning',
  PATTERN_COMPLETION_RETRIEVAL: 'PatternCompletionRetrieval',
  PATTERN_SEPARATION_LEVEL1: 'PatternSeparationLevel1',
  PATTERN_SEPARATION_LEVEL2: 'PatternSeparationLevel2'
}

// should be moved to the localizations files if we introduce other languages to the WP
const CATEGORIES_NAMES = {
  [CATEGORIES.QUESTIONNAIRE]: 'Questionnaire',
  [CATEGORIES.INSIDE_OUTSIDE_LEARNING]: 'Inside Outside Learning',
  [CATEGORIES.INSIDE_OUTSIDE_RETRIEVAL]: 'Inside Outside Retrieval',
  [CATEGORIES.PATTERN_COMPLETION_LEARNING]: 'Pattern Completion Learning',
  [CATEGORIES.PATTERN_COMPLETION_RETRIEVAL]: 'Pattern Completion Retrieval',
  [CATEGORIES.PATTERN_SEPARATION_LEVEL1]: 'Pattern Separation Level 1',
  [CATEGORIES.PATTERN_SEPARATION_LEVEL2]: 'Pattern Separation Level 2'
}

// internal ids, used for vuetify theme colors
const ASSIGNMENT_TYPES_COLORS = {
  EMPTY_ASSIGNMENT: 'empty_assignment',
  INSIDE_OUTSIDE: 'inside_outside',
  PATTERN_SEPARATION: 'pattern_separation',
  PATTERN_COMPLETION: 'pattern_completion',
  VISION_TEST: 'vision_test',
  INFORMATION: 'information',
  QUESTIONNAIRE: 'questionnaire'
}

// internal ids
const ASSIGNMENT_TYPES = {
  INSIDE_OUTSIDE: 'InsideOutside',
  PATTERN_COMPLETION: 'PatternCompletion',
  PATTERN_SEPARATION: 'PatternSeparation',
  VISION_TEST: 'VisionTest',
  QUESTION_INTRODUCTION: 'QuestionIntroduction',
  INFORMATION: 'Information',
  QUESTIONNAIRE: 'Questionnaire',
  EMPTY_ASSIGNMENT: 'EmptyAssignment'
}

// should be moved to the localizations files if we introduce other languages to the WP
const ASSIGNMENT_TYPES_SETTINGS = {
  [ASSIGNMENT_TYPES.INSIDE_OUTSIDE]: {
    name: 'Inside Outside',
    color: ASSIGNMENT_TYPES_COLORS.INSIDE_OUTSIDE,
    priority: 2
  },
  [ASSIGNMENT_TYPES.PATTERN_COMPLETION]: {
    name: 'Pattern Completion',
    color: ASSIGNMENT_TYPES_COLORS.PATTERN_COMPLETION,
    priority: 2
  },
  [ASSIGNMENT_TYPES.PATTERN_SEPARATION]: {
    name: 'Pattern Separation',
    color: ASSIGNMENT_TYPES_COLORS.PATTERN_SEPARATION,
    priority: 2
  },
  [ASSIGNMENT_TYPES.VISION_TEST]: {
    name: 'Vision Test',
    color: ASSIGNMENT_TYPES_COLORS.VISION_TEST,
    priority: 1
  },
  [ASSIGNMENT_TYPES.QUESTION_INTRODUCTION]: {
    name: 'Question Introduction',
    color: ASSIGNMENT_TYPES_COLORS.QUESTIONNAIRE,
    priority: 0
  },
  [ASSIGNMENT_TYPES.INFORMATION]: {
    name: 'Information',
    color: ASSIGNMENT_TYPES_COLORS.INFORMATION,
    priority: 0
  },
  [ASSIGNMENT_TYPES.QUESTIONNAIRE]: {
    name: 'Questionnaire',
    color: ASSIGNMENT_TYPES_COLORS.QUESTIONNAIRE,
    priority: 0
  },
  [ASSIGNMENT_TYPES.EMPTY_ASSIGNMENT]: {
    name: 'Empty Assignment',
    color: ASSIGNMENT_TYPES_COLORS.EMPTY_ASSIGNMENT,
    priority: 0
  }
}

// steps -> assignment type API mapping
const ASSIGNMENT_TYPES_API_MAPPING = {
  [ASSIGNMENT_STEPS_API_TYPES.FADE_LEARNING]: ASSIGNMENT_TYPES.INSIDE_OUTSIDE,
  [ASSIGNMENT_STEPS_API_TYPES.FADE_RETRIEVAL]: ASSIGNMENT_TYPES.INSIDE_OUTSIDE,
  [ASSIGNMENT_STEPS_API_TYPES.PATTERN_COMPLETION]: ASSIGNMENT_TYPES.PATTERN_COMPLETION,
  [ASSIGNMENT_STEPS_API_TYPES.PATTERN_SEPARATION]: ASSIGNMENT_TYPES.PATTERN_SEPARATION,
  [ASSIGNMENT_STEPS_API_TYPES.VISION_TEST]: ASSIGNMENT_TYPES.VISION_TEST,
  [ASSIGNMENT_STEPS_API_TYPES.QUESTIONNAIRE_INTRODUCTION]: ASSIGNMENT_TYPES.QUESTION_INTRODUCTION,
  [ASSIGNMENT_STEPS_API_TYPES.INFORMATION]: ASSIGNMENT_TYPES.INFORMATION,
  [ASSIGNMENT_STEPS_API_TYPES.QUESTIONNAIRE]: ASSIGNMENT_TYPES.QUESTIONNAIRE
}

export {
  SCHEDULING_STRATEGY,
  CATEGORIES,
  CATEGORIES_NAMES,
  ASSIGNMENT_TYPES_COLORS,
  ASSIGNMENT_TYPES,
  ASSIGNMENT_TYPES_SETTINGS,
  ASSIGNMENT_TYPES_API_MAPPING
}
