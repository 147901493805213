/**
 * Name: sidebar.js
 * Description: Sidebar store module.
 * Created by: Jana Schumann on 2018-03-23
 * Last edited by: Milena Malysheva on 2020-10-30
 */

const state = {
  sidebarOpen: true
}

const getters = {
  sidebarOpen: state => state.sidebarOpen
}

const actions = {
  toggleSidebar ({ commit }) {
    commit('toggleSidebar')
  }
}

const mutations = {
  toggleSidebar (state) {
    state.sidebarOpen = !state.sidebarOpen
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
