<!--
 Name: Drawer.vue
 Description: Component to display the drawer.
 Created by: Jana Schumann on 2018-03-23
 Last edited by: Lokeswari Madhusudhana on 2024-02-14
-->

<template>
  <v-navigation-drawer v-model="drawer" app width="290">
    <v-img
      src="@/assets/images/logo/long.svg"
      width="189"
      height="39"
      contain
      class="mb-9 mt-2 ml-3"
    />
    <v-list>
      <template
        v-for="menuItem in menuItems[$store.getters.baseRole]"
      >
        <v-list-group
          v-if="menuItem.children"
          :prepend-icon="menuItem.icon"
          value="true"
          no-action
          :key="menuItem.title"
        >

          <template #activator>
            <v-list-item-content>
              <v-list-item-title>{{ menuItem.title }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="subItem in menuItem.children"
            :key="subItem.title"
            :to="menuItem.route + subItem.route"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ subItem.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list-group>

        <v-list-item
          v-else
          :to="menuItem.route"
          :key="menuItem.title"
        >
          <v-list-item-icon>
            <v-icon>{{ menuItem.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ menuItem.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { ROLES } from '@/constants/users'
import { PARADIGMS, PARADIGM_NAMES } from '@/constants/paradigms'
import { PROJECTS, PROJECT_NAMES } from '@/constants/projects'

export default {
  data: () => ({
    drawer: true,
    menuItems: null
  }),
  computed: {
    open () {
      return this.$store.getters.sidebarOpen
    }
  },
  watch: {
    open (toggle) {
      this.drawer = toggle
    }
  },
  created () {
    const paradigmMenuItems = [
      PARADIGMS.IO,
      PARADIGMS.PC,
      PARADIGMS.PS,
      PARADIGMS.VT
    ].map(paradigm => ({
      title: PARADIGM_NAMES[paradigm],
      route: '/' + paradigm
    }))

    const projectsMenuItems = [
      PROJECTS.PO,
      PROJECTS.SHI,
      PROJECTS.PR,
      PROJECTS.PY
    ].map(paradigm => ({
      title: PROJECT_NAMES[paradigm],
      route: '/' + paradigm
    }))

    this.menuItems = {
      [ROLES.ADMIN]: [
        {
          title: 'Dashboard',
          icon: 'dashboard',
          route: '/admin'
        },
        {
          title: 'Stimuli Library',
          icon: 'image',
          route: '/stimuli',
          children: paradigmMenuItems
        },
        {
          title: 'Stimuli Set Templates',
          icon: 'photo_library',
          route: '/templates',
          children: paradigmMenuItems
        },
        {
          title: 'Question Library',
          icon: 'folder_open',
          route: '/question-library'
        },
        {
          title: 'Text Editor',
          icon: 'edit',
          route: '/text-editor'
        },
        {
          title: 'Activation Codes',
          icon: 'people',
          route: '/activation-codes'
        },
        {
          title: 'Projects',
          icon: 'list',
          route: '/projects',
          children: projectsMenuItems
        }
      ],
      [ROLES.SCIENTIST]: [
        {
          title: 'Dashboard',
          icon: 'dashboard',
          route: '/scientist'
        },
        {
          title: 'Studies',
          icon: 'content_copy',
          route: '/scientist-studies'
        },
        {
          title: 'Data Download',
          icon: 'download',
          route: '/data-download'
        },
        {
          title: 'Question Library',
          icon: 'folder_open',
          route: '/scientist-question-library'
        },
        {
          title: 'Help',
          icon: 'help_outline',
          route: '/help'
        },
        {
          title: 'Settings',
          icon: 'settings',
          route: '/scientist-settings'
        }
      ],
      [ROLES.BUSINESS_MANAGER]: [
        {
          title: 'Projects',
          icon: 'list',
          route: '/projects',
          children: projectsMenuItems
        }
      ]
    }
  }
}
</script>
