/**
 * Name: index.js
 * Description: Config file for the axios instance.
 * Created by: Milena Malysheva on 2021-02-16
 * Last edited by: Milena Malysheva on 2022-12-21
 */

import axios from 'axios'
import cookies from 'vue-cookies'
import router from '@/plugins/router'
import store from '@/plugins/store'
import { BASE_URL_MAP } from '@/constants/general'

// https://medium.com/datadriveninvestor/axios-instance-interceptors-682868f0de2d

// provide custom config here if needed
const axiosInstance = axios.create()

// I prefer to do it here instead of axios.create to make the login/logout handling easier
axiosInstance.interceptors.request.use(config => {
  config.baseURL = BASE_URL_MAP[process.env.VUE_APP_API] + '/api/' + process.env.VUE_APP_API_VERSION
  config.headers.common.Authorization = 'Bearer ' + cookies.get('user-token')
  return config
})

axiosInstance.interceptors.response.use(undefined, err => {
  if (err.response.status === 401) {
    // redirect to the login page if the user is unauthorized
    // theoretically this can only be called when the user-token cookie is expired, because other cases should be
    // handled by the router
    store.dispatch('authLogout').then(() => {
      // if there were multiple calls
      if (router.currentRoute.path !== '/login') {
        router.push({ name: 'Login' })
        store.dispatch('snackbarQueue/showErrorSnackbar', {
          text: 'Session expired.'
        })
      }
    })
  }
  return Promise.reject(err)
})

export default axiosInstance
