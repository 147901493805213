/**
 * Name: vuetify.js
 * Description: Vuetify configuration file.
 * Created by: Milena Malysheva on 2021-09-14
 * Last edited by: Milena Malysheva on 2022-11-10
 */

import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'

import { ASSIGNMENT_TYPES_COLORS } from '@/constants/studies/assignments/settings'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'md'
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#57509C',
        primary_disabled: '#A6A6BF',
        secondary: '#A8A8C1',
        accent: colors.orange.base,
        background: colors.shades.white,
        white: '#FFFFFF',
        bleu_canard: '#00879B',
        warning_red: '#EE6E73',
        link_expired_red: '#D0464C',
        info_blue: '#4A90E2',
        dark_grey: '#4C4C4C',
        action_blue: '#4FA0D8',
        // assignment colors
        [ASSIGNMENT_TYPES_COLORS.EMPTY_ASSIGNMENT]: '#7384A0',
        [ASSIGNMENT_TYPES_COLORS.INSIDE_OUTSIDE]: '#4A90E2',
        [ASSIGNMENT_TYPES_COLORS.PATTERN_SEPARATION]: '#F5A623',
        [ASSIGNMENT_TYPES_COLORS.PATTERN_COMPLETION]: '#50E3C2',
        [ASSIGNMENT_TYPES_COLORS.VISION_TEST]: '#F7D41D',
        [ASSIGNMENT_TYPES_COLORS.INFORMATION]: '#FF8895',
        [ASSIGNMENT_TYPES_COLORS.QUESTIONNAIRE]: '#4C54C7'
      }
    }
  }
})
