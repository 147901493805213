<!--
 Name: Header.vue
 Description: Component to display the header for all pages.
 Created by: Jana Schumann on 2018-03-09
 Last edited by: Milena Malysheva on 2022-02-08
-->

<template>
  <div>
    <!-- toolbar -->
    <v-app-bar app color="white" fixed clipped-right>
      <v-app-bar-nav-icon
        v-if="isAuthenticated"
        @click="toggle"
      />
      <router-link to="/" v-else>
        <v-img
          src="@/assets/images/logo/long.svg"
          width="189px"
          height="39px"
          class="ml-3"
          contain
        />
      </router-link>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn
          v-if="isAuthenticated"
          color="black"
          large
          text
          @click="logout"
          id="logout-button"
        >
          Logout
        </v-btn>
        <v-btn
          v-else
          color="black"
          large
          text
          to="/login"
          id="login-button"
        >
          Login
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['isAuthenticated'])
  },
  methods: {
    toggle () {
      this.$store.dispatch('toggleSidebar')
    },
    logout () {
      this.$axios.delete('/Login')
        .then(() => {
          // go back to login page & remove cookies
          this.$store.dispatch('authLogout').then(() => this.$router.push({ name: 'Login' }))
        })
        .catch(() => {
          this.$store.dispatch('snackbarQueue/showErrorSnackbar', {
            text: 'An error occurred. You could not be logged out.'
          })
        })
    }
  }
}
</script>

<style scoped>

.v-app-bar {
  -moz-box-shadow: 0 2px 4px -1px rgba(0,0,0,.2),0 4px 5px 0 rgba(0,0,0,.14),0 1px 10px 0 rgba(0,0,0,0);
  -webkit-box-shadow: 0 2px 4px -1px rgba(0,0,0,.2),0 4px 5px 0 rgba(0,0,0,.14),0 1px 10px 0 rgba(0,0,0,0);
  box-shadow: 0 2px 4px -1px rgba(0,0,0,.2),0 4px 5px 0 rgba(0,0,0,.14),0 1px 10px 0 rgba(0,0,0,0);
}

</style>
