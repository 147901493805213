/**
 * Name: snackbarQueue.js
 * Description: SnackbarQueue store module.
 * Created by: Milena Malysheva on 2019-08-02
 * Last edited by: Milena Malysheva on 2020-10-30
 */

const state = {
  snackbarQueue: []
}

const getters = {
  queue: state => state.snackbarQueue
}

const actions = {
  showSnackbar ({ commit }, snackbar) {
    commit('showSnackbar', snackbar)
  },
  showErrorSnackbar ({ commit }, snackbar) {
    commit('showErrorSnackbar', snackbar)
  },
  closeSnackbar ({ commit }) {
    commit('closeSnackbar')
  }
}

const mutations = {
  showSnackbar (state, snackbar) {
    state.snackbarQueue.push({ ...snackbar, color: 'black' })
  },
  showErrorSnackbar (state, snackbar) {
    state.snackbarQueue.push({ ...snackbar, color: 'pink darken-2' })
  },
  closeSnackbar (state) {
    state.snackbarQueue.shift()
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
