<!--
 Name: App.vue
 Description: The container for the application.
 Created by: Jana Schumann on 2018-03-09
 Last edited by: Milena Malysheva on 2022-09-26
-->

<template>
  <div id="app">
    <v-app :style="{ background: $vuetify.theme.themes[theme].background }">
        <div>
          <!-- cookie banner -->
          <cookie-law theme="neotiv" buttonText="Ok">
            <template #message>
              I hereby accept the privacy policy of neotiv GmbH in accordance with the General Data Protection
              Regulation (GDPR). We are only using technical necessary cookies at this website.
              All relevant information can be found in the <router-link to="/data">data protection</router-link>.
            </template>
          </cookie-law>
          <!--global snackbar queue-->
          <snackbar-queue></snackbar-queue>
          <!-- layout -->
          <transition name="fade" mode="out-in">
            <router-view gap></router-view>
          </transition>
          <app-header />
          <app-footer/>
          <app-drawer v-if="$store.getters.isAuthenticated"/>
        </div>
    </v-app>
  </div>
</template>

<script>
import CookieLaw from 'vue-cookie-law'

import SnackbarQueue from '@/components/layout/SnackbarQueue'

import Header from '@/components/layout/Header'
import Drawer from '@/components/layout/Drawer'
import Footer from '@/components/layout/Footer'

export default {
  name: 'App',
  components: {
    cookieLaw: CookieLaw,
    appHeader: Header,
    appDrawer: Drawer,
    appFooter: Footer,
    SnackbarQueue
  },
  computed: {
    theme () {
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    }
  }
}
</script>

<style>
  /* should be moved to the variables.scss */
  body {
    font-family: 'Hind', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
  }

  /* I don't understand why do we need this */
  * {
    text-transform: none !important;
  }

  /* fade animation */

  .fade-enter {
    opacity: 0;
  }

  .fade-enter-active {
    transition: opacity 1s;
  }

  .fade-leave {
    opacity: 1;
  }

  .fade-leave-active {
    transition: opacity 1s;
    opacity: 0;
  }

  /* end of fade animation */

  /* cookie banner */

  .Cookie--neotiv {
    background: #424242;
    color: #FFFFFF;
    padding: 1.250em;
  }

  .Cookie--neotiv .Cookie__button {
    background: #FF9900;
    padding: 0.625em 3.125em;
    color: #FFFFFF;
    border-radius: 20px;
  }

  .Cookie--neotiv div.Cookie__button:hover {
    background: darken(#FF9900, 10%);
  }

  /* end of cookie banner */

</style>
